import AxiosClass, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosStatic,
  InternalAxiosRequestConfig,
  RawAxiosResponseHeaders
} from "axios";
import Qs from "qs";
import {IPaginator} from "@/contracts/i-paginator";
import StringUtil from '@/utils/string-utils';
import DateUtil from '@/utils/date-utils';

import * as FileSaver from "file-saver";
import router from '@/router';
import {MessageBox} from "element-ui";
import CustomCache from "@/utils/custom-cache";
import {HandledApiError, UnHandledApiError} from "@/bootstraps/error-handler";
import {judgeLang} from "@/bootstraps/locale";
import {
  AxiosCacheInstance,
  buildWebStorage,
  CacheAxiosResponse,
  CacheRequestConfig,
  setupCache
} from "axios-cache-interceptor";
import {InterpreterResult} from "axios-cache-interceptor/src/header/types";
import appType from "@/app-types";
let messageBoxLock = false;

export interface IFindOption {
  _sort?: string;
  _order?:'desc'|'asc';

  _page?: number;
  _limit?: number;

  _belongsTo?: string|number;
  _hasMany?: string|number;

  _cols?: string|undefined; // hoge,fuga,foo,bar
}

// https://qiita.com/koushisa/items/ac908d81361534264d35
export function saveDownloadedBlob(response: AxiosResponse, filename): void {
  const blob = new Blob([response.data], {
    type: response.data.type
  });
  FileSaver.saveAs(blob, filename);
}

const handleBlobError:(err:any) => Promise<{message: string}> = (error) => {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject();
    };
    fileReader.onload = () => {
      resolve(JSON.parse(fileReader.result as string));
    };
    fileReader.readAsText(error.response.data);
  });
}

export const handleAxiosError = async (error:any) => {
  if (error.response) {
    const errorText = error.request.responseType === "blob" ? (await handleBlobError(error)).message : error.response.data.message;
    console.debug(error);

    if(error.response.data.handled_message) {
      throw new HandledApiError(error.response.data.handled_message);
    } else if (error.response.status === 401 || error.error === 'login_required') {
      throw new HandledApiError("再ログインが必要です。画面をリロードしてください。", 401);
    } else if (error.response.status === 403) {
      throw new HandledApiError(`指定されたデータにはアクセスできません(${errorText})`, 403);
    } else if(error.response.status === 404) {
      throw new HandledApiError(`指定されたデータは見つかりませんでした（${errorText}）`, 404);
    } else if(error.response.status === 422) {
      const errors = error.response.data.errors;
      if (errors && Object.values(errors).length > 0) {
        const validationError = Object.values(errors)[0];
        throw new HandledApiError(`${validationError}`, 422);
      } else {
        throw new HandledApiError(`${errorText}`, 422);
      }
    } else if(error.response.status === 429) {
      throw new HandledApiError(`通信数がリミットがに達しました。少し時間をおいて再度お試しください（${errorText}）`, 429);
    } else if(error.response.status === 470) {
      throw new HandledApiError(errorText, 470);
    } else if(error.response.status === 471) {
      if (!messageBoxLock) {
        messageBoxLock = true;
        MessageBox.confirm("以前に登録された情報を確認するためには、プランを更新してください。", 'プランの有効期限が切れています', {
          confirmButtonText: 'プラン更新へ',
          showCancelButton: false,
          confirmButtonClass: 'c-button primary no-focus',
          closeOnClickModal: false,
          closeOnPressEscape: false,
        }).then(() => {
          router.push({name: 'mypage.company.invoice'});
        }).finally(() => {
          messageBoxLock = false;
        });
      }
      throw new HandledApiError('プランの有効期限が切れています', 471);
    } else if(error.response.status === 472) {
      if (!messageBoxLock) {
        messageBoxLock = true;
        MessageBox.confirm("データを更に登録するには、既存データを削除するか、クレジットカードを登録してください（プランが変更され、課金されます）", 'クレジットカードの登録が必要です', {
          confirmButtonText: 'カード登録へ',
          showCancelButton: false,
          confirmButtonClass: 'c-button primary no-focus',
          closeOnClickModal: false,
          closeOnPressEscape: false,
        }).then(() => {
          window.open(router.resolve({name: 'mypage.company.invoice'}).href, '_blank');
        }).finally(() => {
          messageBoxLock = false;
        });
      }
      throw new HandledApiError('プランの更新が必要です', 472);
    } else if(errorText) {
      throw new UnHandledApiError(`サーバーで不明なエラーが発生しました(${errorText})`);
    } else {
      throw new UnHandledApiError(`サーバーで不明なエラーが発生しました(${error.response.statusText})`);
    }
  } else if(error.request) {
    throw new HandledApiError(`通信中にエラーが発生しました(${error.message})`);
  } else {
    throw new UnHandledApiError(`不明なエラーが発生しました(${error.message})`);
  }
};

const axios = setupCache(
  AxiosClass.create({
    baseURL: (process.env.VUE_APP_API_HOST || 'localhost:3000').replace(/\/$/, "") + "/",
    withCredentials: true,
    paramsSerializer: {
      serialize: (p: Record<string, any>) => Qs.stringify(p),
    },
  }),
  {
    // default not cache
    headerInterpreter: (headers?: CacheAxiosResponse['headers']) => {
      return 'dont cache' as InterpreterResult;
    },
    // debug: console.log,
    ttl: 0, // default false
    cacheTakeover: false, // prevent additional request header (Cache-Control, Pragma, Expires)
  }
);
axios.interceptors.request.use( async (req:InternalAxiosRequestConfig) => {
  req.params = DateUtil.formatDateRecursive(req.params);
  req.data = DateUtil.formatDateRecursive(req.data);
  req.params = StringUtil.toSnakeCaseObjectKey(req.params);
  req.data = StringUtil.toSnakeCaseObjectKey(req.data);

  req.headers['X-Target-Language'] = judgeLang();
  req.headers['X-App-Type'] = appType;

  return req;
}, error => {
  if ((error as any).skipErrorHandler) return;
  return handleAxiosError(error);
});
axios.interceptors.response.use((res:CacheAxiosResponse) => {
  if (res.cached) {
    res.data = StringUtil.toCamelCaseObjectKey(res.data);
    return res;
  }
  if (res.request.responseType === 'blob') return res;

  res.data = StringUtil.toCamelCaseObjectKey(res.data);
  return res;
}, error => {
  if ((error as any).config && error.config.skipErrorHandler) return;
  return handleAxiosError(error);
});

export const CacheParam = { cache: { ttl: 1 * 60 * 1000 } };

export default class RepositoryBase<EntityType> {
  protected endpoint:string = "";
  protected ctor:null | (new(data) => EntityType) = null;

  protected axios: AxiosCacheInstance;

  constructor() {
    this.axios = axios;
  }

  // region Basic Methods
  public async get(endpoint:string, params:any = {}, config: CacheRequestConfig = {}, cacheSeconds = 0):Promise<CacheAxiosResponse> {
    const url = endpoint.replace(/^\//, "");
    const cache = cacheSeconds > 0
      ? { interpretHeader: false, ttl: cacheSeconds * 1000 }
      : false;
    return this.axios.get(url, Object.assign({}, { cache: cache }, { params: params }, config));
  }

  public async post(endpoint:string, data:any = {}, config:AxiosRequestConfig = {}):Promise<AxiosResponse> {
    const url = endpoint.replace(/^\//, "");
    return this.axios.post(url, data, config);
  }

  public async put(endpoint:string, data:any = {}):Promise<AxiosResponse> {
    const url = endpoint.replace(/^\//, "");
    return this.axios.put(url, data);
  }

  public async delete(endpoint:string, data:any = {}):Promise<AxiosResponse> {
    const url = endpoint.replace(/^\//, "");
    return this.axios.delete(url, {data: data});
  }
  // endregion

  // region Find Methods

  // データをgetして、EntityTypeに指定されたオブジェクトにパースして返します
  //
  // @param 基本的に以下のライブラリと同様のルールでパラメータを設定してます
  // https://github.com/typicode/json-server
  //
  // @param: さらに、_as_paginationをつけてあげると、ページネーション形式のデータに変換されます
  //
  protected async findAndParse(filter = {}, opt:IFindOption = {}, asPagination: boolean = false) :Promise<EntityType[]|IPaginator<EntityType>> {
    const res = await this.get(this.endpoint, Object.assign({}, filter, opt, {_as_pagination: asPagination}), );
    return this.parseResponse(res, asPagination);
  }

  protected parseResponse(res:AxiosResponse, asPagination:boolean = false):EntityType[]|IPaginator<EntityType> {
    if (asPagination) {
      return this.toPagination(res, this.ctor!) as IPaginator<EntityType>;
    } else {
      return res.data.map(d => new this.ctor!(d)) as EntityType[];
    }
  }
  protected toPagination(res, ctor:null | (new(data) => any) = this.ctor) {
    return {
      data: (res.data.data ? res.data.data.map(d => ctor ? new ctor(d) : d) : []),
      currentPage: Number(res.data.currentPage),
      perPage: Number(res.data.perPage),
      total: Number(res.data.total)
    };
  }

  // TODO: 以下メソッド、protectedにしたい（参照がわかりづらくなるから、明示的にメソッドを指定する形に移行したい
  public findAll(opt?:IFindOption):Promise<EntityType[]> ;
  public findAll(opt:IFindOption, asPagination:boolean):Promise<IPaginator<EntityType>> ;

  public async findAll(opt:IFindOption = {}, asPagination:boolean = false):Promise<EntityType[]|IPaginator<EntityType>> {
    return this.findAndParse({}, opt, asPagination);
  }

  public async count(opt:IFindOption = {}, asPagination:boolean = false):Promise<number> {
    const res = await this.get(`${this.endpoint}/count`);
    return Number(res.data);
  }

  public async findById(id:number, params = {}): Promise<EntityType> {
    const res = await this.get(`${this.endpoint}/${id}`, params);
    return new this.ctor!(res.data);
  }
  // endregion

  public async create(data: Partial<EntityType>):Promise<AxiosResponse> {
    return this.post(this.endpoint, data);
  }

  public async update(id, data:Partial<EntityType>) {
    return this.put(this.endpoint + `/${id}`, data);
  }

  public async destroy(id) {
    return this.delete(this.endpoint + `/${id}`);
  }

  public async destroyBulk(idList:number[]|string[]) {
    return await this.delete(this.endpoint, idList);
  }

  // region Helper methods
  public static saveDownloadedBlob(response: AxiosResponse, filename): void {
    saveDownloadedBlob(response, filename);
  }
  // endregion Helper Methods

  protected async handleCache<T>(cache:CustomCache<T>, func:() => Promise<T>, expireSeconds:number = 1800): Promise<T> {
    if (!cache.isCacheValid) {
      const res = await func();
      cache.set(res, expireSeconds);
    }
    return cache.get()!;
  }
}

export function extractFileNameFromResponseHeader(res: AxiosResponse):string {
  const contentDisposition = res.headers['content-disposition'];
  if (!contentDisposition) return "";
  // attachment; filename=%E6%A0%84%E9%A4%8A%E6%88%90%E5%88%86%E6%A0%B9%E6%8B%A0%E8%B3%87%E6%96%99_%E9%87%8D%E8%A4%87%E5%8E%9F%E6%9D%90%E6%96%99%E3%83%86%E3%82%B9%E3%83%88_20230315.xlsx
  // attachment; filename=__20230315.xlsx; filename*=utf-8''%E6%A0%84%E9%A4%8A%E6%88%90%E5%88%86%E6%A0%B9%E6%8B%A0%E8%B3%87%E6%96%99_%E9%87%8D%E8%A4%87%E5%8E%9F%E6%9D%90%E6%96%99%E3%83%86%E3%82%B9%E3%83%88_20230315.xlsx
  const filename = contentDisposition.match(/filename\*?=(utf-8'')?([^;]*?)$/);
  if (!filename) return "";
  return decodeURIComponent(filename[2]);
}
