import {i18n} from "@/bootstraps/locale";

type Trigger = 'blur' | 'change' | 'none';

const requiredValidator = (rule, value, callback) => {
  if (value === '' || value === null || value === undefined) return callback(new Error(i18n.t('この項目は必須です')));
  if (typeof value === 'string' && value.trim() === '') return callback(new Error(i18n.t('この項目は必須です')));
  return callback();
};
export const requiredRule = { required: true, trigger: 'none', validator: requiredValidator }; // requiredクラスをform-itemに付与するためにrequired: true必要
export const requiredOnBlurRule = { required: true, trigger: 'blur', validator: requiredValidator };
export const requiredOnChangeRule = { required: true, trigger: 'change', validator: requiredValidator };
export const typeNumberRule = { type: 'number', message: i18n.t('validations.数値を入力してください'), trigger: 'blur' };
export const dateRule = { type: 'date', message: i18n.t('validations.日付を入力してください'), trigger: 'blur' };
export const MAX_SIGNED_INT_VALUE = 2147483647;
export const dateStringRule = { trigger: 'blur',
  validator: (rule, value, callback) => {
    if (!value) return callback();

    if(value.match(/[12]\d{3}[-\/](0[1-9]|1[0-2])[-\/](0[1-9]|[12]\d|3[01])/)) {
      return callback();
    }
    return callback(new Error(i18n.t('validations.日付形式を入力してください')));
  }
};
export const typeNumberOrEmptyRule = { trigger: 'blur',
  validator: (rule, value, callback) => {
    if (value === '' || value === null || value === undefined) return callback();
    if(!isNaN(value)) return callback();

    return callback(new Error(i18n.t('validations.数値を入力してください')));
  }
};
export const getTypeIntRule = function(denyNegative = true, allowEmpty = true) {
  return {
    trigger: 'blur',
    validator: (rule, value, callback) => {
      const isEmpty = value === '' || value === null || value === undefined;
      if (isEmpty) {
        if (allowEmpty) {
          return callback();
        } else {
          return callback(new Error(i18n.t('validations.値を入力してください')));
        }
      }

      if (!Number.isInteger(value)) return callback(new Error(i18n.t('validations.整数を入力してください')));
      if (Number(value) > MAX_SIGNED_INT_VALUE) return callback(new Error(i18n.t('validations.max', [MAX_SIGNED_INT_VALUE])));

      if (denyNegative) {
        if (Number(value) < 0) return callback(new Error(i18n.t('validations.正の整数を入力してください')));
      }

      return callback();
    }
  };
}
// export const typeIntegerRule = { type: 'integer', message: i18n.t('validations.整数を入力してください'), trigger: 'blur' };
export const typeNonNegativeIntRule = getTypeIntRule();

export const typeEmailRule = { type: 'email', message: i18n.t('メールアドレスを入力してください'), trigger: 'blur' };
export const typeTelno = {trigger: 'blur',
  validator: (rule, value:string, callback) => {
    if (!value) return callback();

    // 固定電話1 ex: 03-2222-2222 or 0322222222
    // 固定電話2 ex: 096-222-2222 or 0962222222
    // 固定電話3 ex: 0422-22-2222 or 0422222222
    // 携帯・IP電話 ex: 080-2222-2222 or 08022222222
    // フリーダイヤル: 0120-222-222 or 0120222222
    // 国際: +81 xxx
    if (value.match(/^\+?[\-\d\s\(\)（）]+?$/)){
      return callback();
    }
    return callback(new Error(i18n.t("validations.tel")));
  }
};

const getRangeUnit = type => type === 'char'
  ? i18n.t('validations.文字')
  : (type === 'array' ? i18n.t('項目') : '');

export function createMinRule(min:number, type:'char'|'number'|'array' = 'number') {
  if (type === 'number') {
    return {
      trigger: 'blur',
      validator: (rule, val, callback) => {
        if (!val && val !== 0) return callback();
        val >= min ? callback() : callback(new Error(i18n.t('validations.min', [min])))
      },
    }
  } else if (type === 'array') {
    return {
      type: 'array',
      trigger: 'blur',
      validator: (rule, val, callback) => {
        return val.length >= min
          ? callback()
          : callback(new Error(i18n.t('validations.min', [min + getRangeUnit(type)])))
      },
    };
  } else {
    return { min: min, message: i18n.t('validations.min', [min + getRangeUnit(type)]), trigger: 'blur' };
  }
}
export const notEmptyArrayRule = createMinRule(1, 'array');

export function createMaxRule(max:number, type:'char'|'number'|'array' = 'number') {
  if (type === 'number') {
    return {
      trigger: 'blur',
      validator: (rule, val, callback) => {
        if (!val && val !== 0) return callback();
        max >= val ? callback() : callback(new Error(i18n.t('validations.max', [max])))
      },
    }
  } else  {
    return { max: max, message: i18n.t('validations.max', [max + getRangeUnit(type)]), trigger: 'blur' };
  }
}
export function createMinStringRule(min:number) {
  return { min: min, message: i18n.t('validations.min', [min.toString() + i18n.t('validations.文字')]), trigger: 'blur' };
}
export function createMaxStringRule(max:number = 255) {
  return { max: max, message: i18n.t('validations.max', [max.toString() + i18n.t('validations.文字')]), trigger: 'blur' };
}

export function createRangeRule(min:number, max:number, type:'char'|'number'|'array' = 'number') {
  const unit = getRangeUnit(type);

  return {
    trigger: 'blur',
    validator: (rule, value, callback) => {
      if (type === 'number' && Number(value) >= min && Number(value) <= max) return callback();
      if (value.length >= min && value.length <= max) return callback();

      return callback(new Error(i18n.t('validations.between', { min: min + unit, max: max + unit})));
    }
  };
}

export function createDecimalRule(maxTotalLength:number, maxDecimalLength:number, minDecimalLength?:number) {
  return {
    trigger: 'blur',
    validator: (rule, value, callback) => {
      if (value === null || value === undefined) return callback();

      if (value === '-') {
        return callback(new Error(i18n.t('数値を入力してください')));
      }

      const vals = value.toString().split('.');
      const intPartLen = vals[0].length;
      const decimalPartLen = vals.length > 1 ? vals[1].length : 0;

      const maxIntLength = maxTotalLength - maxDecimalLength;
      if (intPartLen > maxIntLength) {
        return callback(new Error(i18n.t('validations.decimal.int', [maxIntLength])));
      }
      if (decimalPartLen > maxDecimalLength) {
        return callback(new Error(i18n.t('validations.decimal.decimal', [maxDecimalLength])));
      }
      if (minDecimalLength && decimalPartLen < minDecimalLength) {
        return callback(new Error(i18n.t('validations.decimal.minDecimal', [minDecimalLength])));
      }

      return callback();
    }
  };
}

export function createProhibitedCharsRule(chars: string[], trigger:Trigger = 'blur', customMessage?: string) {
  return {
    trigger: trigger,
    validator: (rule, value, callback) => {
      if (!value) return callback();

      const str = value.toString();
      const invalidChars = chars.filter(char => str.includes(char));
      if (invalidChars.length > 0) {
        const errMsg = customMessage || i18n.t('validations.prohibitedChars', { chars: invalidChars.join('')});
        return callback(new Error(errMsg));
      }
      return callback();
    }
  };
}
