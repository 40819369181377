<template>
  <div ref="wrapper" class="c-filterable-select">
    <div class="el-input el-input--suffix c-filterable-select__input-display"
         :class="[size ? `el-input--${size}` : '', {'is-disabled': disabled}]"
         @click="textBoxClicked"
         @mouseenter="onMouseEnter"
         @mouseleave="onMouseLeave">
      <div
        class="el-input__inner u-pointer u-flex u-flex--align-center"
        :class="{ disabled: disabled, ...getDisplayTextClass(displayText) }"
      >
        <template v-if="displayText">
          <slot name="text" :item='selectedOption' :text="displayText"><span class="u-scroll-x">{{ displayText }}</span></slot>
        </template>
        <template v-else>
          <span class="u-color-placeholder u-scroll-x">{{ disabled ? '' : placeholder }}</span>
        </template>
      </div>

      <div class="el-input__suffix">
        <div class="el-input__suffix-inner">
          <slot name="suffix">
            <i v-show="!showClose" class="el-select__caret el-input__icon el-icon-arrow-up" :class="{'is-reverse': !visibleDropdown}"></i>
            <i v-if="showClose" class="el-select__caret el-input__icon el-icon-circle-close" @click="deleteSelected"></i>
          </slot>
        </div>
      </div>
    </div>

    <div
      v-show="visibleDropdown"
      ref="dropdown"
      class="c-filterable-select__dropdown"
      :class="{'align-right': !appendToBody && dropdownAlignRight }"
      :style="{ width: dropdownWidthPx, ...dropdownStyle }"
    >
      <div class="el-input el-input--prefix">
        <i class="icon-search c-filterable-select__icon-search el-input__icon"></i>
        <input ref="filterTextBox" type="text" class="c-filterable-select__input-filter"
               @keydown.enter.capture="handleKeydownEnter"
               @compositionstart.capture="preventEnter"
               @compositionend.capture="enableEnter"
               @keydown.down.stop.prevent="navigateOptions('next')"
               @keydown.up.stop.prevent="navigateOptions('prev')"
               :placeholder="searchPlaceholder" v-model="query" />
      </div>

      <ul class="u-pt5">
        <OptimizedFilterableSelectOption v-if="showNewOption"
                                         class="new-item" :parent="self" :item="query">
          <span>{{query}}</span>
          <span class="new-item-tag">{{$t('自由入力')}}</span>
        </OptimizedFilterableSelectOption>
        <li class="c-filterable-select__dropdown__items">
          <RecycleScroller class="c-filterable-select__dropdown__items__scroll" ref="scroll"
                            :items="filteredItems" :item-size="30" :key-field="itemKeyProp || itemValueProp"
                            v-slot="{ item }">
            <slot :item="item" :parent="self"></slot>
          </RecycleScroller>
          <div v-if="!filteredItems.length && !allowCreate" class="c-filterable-select__dropdown__group">{{ $t('該当する項目がありません。') }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  // ref: https://mseeeen.msen.jp/filterable-dropdown-list-with-vuejs/

  import Vue from 'vue';

  import IMEHandler from '@/components/mixins/IMEHandler';
  import StringUtils from '@/utils/string-utils';
  import isString from 'lodash/isString';

  import { valueEquals } from 'element-ui/src/utils/util';
  import {i18n} from "@/bootstraps/locale";

  export default Vue.extend({
    mixins: [IMEHandler],
    props: {
      value: null,

      clearable: Boolean,

      appendToBody: {type: Boolean, default: false},

      size: String,
      dropdownWidth: {type: String | Number, default: undefined},
      dropdownAlignRight: {type: Boolean, default: false},
      dropdownStyle: {type: Object, default: () => ({})},
      disabled: {type: Boolean, default:false},
      enableCacheDisplayText: {type: Boolean, default: true},
      getDisplayTextClass: {type: Function, default: (string) => []},

      // trueにすると、一覧にない値を自由入力することができます。その場合は文字列がmodelに送られます
      allowCreate: {type: Boolean, default: false},
      // 自由入力は許可しないが、valueがtextの場合は一覧になくても表示する
      allowShowString: {type: Boolean, default: false},

      items: { type: Array, required: true },
      itemValueProp: { type: String, default: null }, // 選択肢がオブジェクトの場合に値として扱うプロパティ名
      itemLabelProp: { type: String, default: null }, // 選択肢がオブジェクトの場合に、どのプロパティをラベルとして扱うか
      itemSearchProp: { type: String, default: null }, // 選択肢がオブジェクトの場合に絞り込みに使用するプロパティ名. defaultはitemLabelPropの値
      itemKeyProp: { type: String, default: null }, // :key-fieldをvaluePropと別の値にしたい時に使用(主にvalueがnullになる時)

      // 自前のロジックで絞り込みをする場合のメソッド
      remoteMethod: {type: Function, required: false},

      // toHankakuKanaLowerした値同士で比較するか。データセットがすでに変換されている場合は、パフォーマンス向上のためfalseにしてください
      convertOnSearch: {type: Boolean, default: true},

      placeholder: {
        type: String,
        default: function() { return this.allowCreate ? i18n.t('選択または自由入力で作成') : i18n.t('選択'); }
      },
      searchPlaceholder: { // allowCreateの後に定義すること！(じゃないとthis.allowCreateがundefinedになる)
        type: String,
        default: function() { return this.allowCreate ? i18n.t('検索または自由入力で作成') : i18n.t('ここにキーワードを入力して検索'); }
      },
    },
    data() {
      return {
        query: '',
        visibleDropdown: false,
        inputHovering: false,
        cachedDisplayText: "",
        hoverIndex: -1,

        filteredItems: this.items,
        self: this
      };
    },
    computed: {
      dropdownWidthPx() {
        if (isString(this.dropdownWidth)) return this.dropdownWidth;
        return this.dropdownWidth + 'px';
      },
      selectedOption() {
        return this.items.find(item => {
          const shouldUseItemIntact = isString(item) || !this.itemValueProp;
          const itemValue = (shouldUseItemIntact ? item : item[this.itemValueProp]);
          return this.value === itemValue;
        }) || ((this.allowShowString || this.allowCreate) && this.value);
      },
      displayText() {
        if (this.selectedOption) {
          const shouldUseItemIntact = isString(this.selectedOption) || !this.itemLabelProp;
          this.cachedDisplayText = shouldUseItemIntact ? this.selectedOption : this.selectedOption[this.itemLabelProp];
          return this.cachedDisplayText;
        } else if (this.enableCacheDisplayText && this.cachedDisplayText) {
          return this.cachedDisplayText;
        } else {
          return "";
        }
      },
      iconClass() {
        return !this.visibleDropdown ? 'arrow-up is-reverse' : 'arrow-up';
      },
      showClose() {
        return this.clearable && this.value !== null && this.inputHovering;
      },
      showNewOption() {
        if (!this.allowCreate) return false;
        if (!this.query) return false;

        return this.items.filter(i => {
          return (this.itemLabelProp ? i[this.itemLabelProp] : i) === this.query
        }).length === 0;
      },
    },
    watch: {
      visibleDropdown(val) {
        this.$nextTick(() => {
          if (val) {
            document.addEventListener("click", this.handleClickOutside);
            this.$refs.filterTextBox.focus();
          } else {
            document.removeEventListener("click", this.handleClickOutside);
          }
        });
      },
      query(val) {
        this.filteredItems = this.filterItems(StringUtils.toHankakuKanaLower(this.query));
      },
      items(val) {
        this.filteredItems = this.filterItems(StringUtils.toHankakuKanaLower(this.query));
      },
      value(val) {
        if (!val) {
          this.cachedDisplayText = '';
        }
      }
    },
    created() {
      if (this.allowCreate || this.allowShowString) {
        this.cachedDisplayText = this.value;
      }
    },
    mounted() {
      if (this.appendToBody) {
        document.body.appendChild(this.$refs.dropdown);
      }
    },
    updated() {
      if (this.appendToBody) {
        this.setDropdownPosition();
      }
    },
    beforeDestroy() {
      if (this.appendToBody) {
        document.body.removeChild(this.$refs.dropdown);
      }
    },
    methods: {
      setDropdownPosition() {
        const rect = this.$refs.wrapper.getBoundingClientRect();
        if (this.dropdownAlignRight) {
          this.$refs.dropdown.style.left = (rect.right + window.scrollX - this.dropdownWidth) + 'px';
        } else {
          this.$refs.dropdown.style.left = (rect.left + window.scrollX) + 'px';
        }
        this.$refs.dropdown.style.top = (rect.top + rect.height + window.scrollY) + 'px';
        console.log(this.$refs.dropdown.style);
      },
      filterItems(needle) {
        this.hoverIndex = -1;

        if (this.remoteMethod) {
          return this.remoteMethod(needle);
        }
        if (!needle) return this.items;

        const filtered = this.items.filter(item => {

          if (item.isHeading) {
            return true;
          }

          if (!this.itemValueProp && isString(item)) {
            return StringUtils.toHankakuKanaLower(item).indexOf(needle) !== -1;
          }

          const haystack = item[this.itemSearchProp || this.itemLabelProp];

          if (!this.convertOnSearch) {
            return haystack.indexOf(needle) !== -1;
          }

          if (!item.__labelHaystackCache) {
            item.__labelHaystackCache = StringUtils.toHankakuKanaLower(haystack);
          }

          return item.__labelHaystackCache.indexOf(needle) !== -1;
        });

        // groupのheadingで、内訳がないものは消す処理
        return filtered.reduce((acc, cur) => {
          // 直前のgroup(heading)の中身が空 = headingが連続しているとき、直前のheadingは結果に含めない
          if (acc.length) {
            const prev = acc[acc.length - 1];
            if (prev.isHeading && cur.isHeading) acc.pop();
          }

          // 最後のHeadingが空 = 最後の項目がheadingなら、そのheadingは結果に含めない
          if (filtered[filtered.length - 1] === cur && cur.isHeading) return acc;

          return acc.concat(cur);
        }, []);
      },
      handleClickOutside(e) {
        const target = e.target.closest(".c-filterable-select");
        if (target === this.$refs.wrapper) return;
        this.visibleDropdown = false;
      },
      onMouseEnter() {
        if (this.disabled) return;
        this.inputHovering = true;
      },
      onMouseLeave() {
        this.inputHovering = false;
      },
      textBoxClicked() {
        if (this.disabled) return;
        this.visibleDropdown = !this.visibleDropdown;
      },

      itemSelected(item) {
        // TODO: とりあえず、項目がisHeadingプロパティtrueをもっている場合はHeaderとして、disabledの場合はdisabledにしている
        // どちらも選択不可。 ここのインターフェースはもっと良い感じにしたい
        if (item.isHeading) return;
        if (item._option_disabled) return;

        const isNew = this.showNewOption && this.hoverIndex < 0;
        const value = (isNew || !this.itemValueProp) ? item : item[this.itemValueProp];

        this.$emit("input", value);
        this.emitChange(value);
        this.visibleDropdown = false;
      },
      onPressEnter() {
        if (this.showNewOption && this.hoverIndex < 0) {
          this.itemSelected(this.query);
          return;
        }

        if (this.hoverIndex < 0) return;

        const selectedItem = this.filteredItems[this.hoverIndex];
        if (!selectedItem) return;

        this.itemSelected(selectedItem);
      },
      isHover(item) {
        if (item.isHeading) return false;
        return this.hoverIndex === this.filteredItems.indexOf(item);
      },
      hoverItem(item) {
        if (item.isHeading) return;
        if (item._option_disabled) return;
        this.hoverIndex = this.filteredItems.indexOf(item);
      },
      deleteSelected(event) {
        event.stopPropagation();
        const value = null;
        this.cachedDisplayText = '';
        this.$emit('input', value);
        this.emitChange(value);
        this.visibleDropdown = false;
        this.$emit('clear');
      },
      emitChange(val) {
        if (!valueEquals(this.value, val)) {
          this.$emit('change', val);
        }
      },
      navigateOptions(direction) {
        if (this.filteredItems.length === 0) return;

        // if (this.optionsAllDisabled) return;

        if (direction === 'next') {
          this.hoverIndex++;

          // headingならもういっこすすめる
          if(this.filteredItems.length > this.hoverIndex && this.filteredItems[this.hoverIndex].isHeading) {
            this.hoverIndex++;
          }

          if (this.hoverIndex === this.filteredItems.length) { // endならstartにもどる
            this.hoverIndex = this.showNewOption ? -1 : 0;
          }
        } else if (direction === 'prev') {
          this.hoverIndex--;
          // headingならもういっこもどす
          if(this.hoverIndex > 0 && this.filteredItems[this.hoverIndex].isHeading) {
            this.hoverIndex--;
          }
          // startならendにとぶ
          if (this.hoverIndex < -1) {
            this.hoverIndex = this.filteredItems.length - 1;
          }
        }
        if (this.hoverIndex > -1 && this.filteredItems[this.hoverIndex]._option_disabled) {
          this.navigateOptions(direction);
        }
        this.$nextTick(() => this.scrollToOption());
      },
      scrollToOption() {
        const itemHeight = 30;
        this.$refs.scroll.$el.scrollTop = itemHeight * this.hoverIndex;

        // 微調整
        if (this.hoverIndex === 0) {
          this.$refs.scroll.$el.scrollTop -= 100;
        } else if(this.hoverIndex === this.filteredItems.length - 1) {
          // 最後尾
        } else {
          this.$refs.scroll.$el.scrollTop -= itemHeight;
        }
      },
    },
  });
</script>

<style lang="scss">
  .c-filterable-select {
    position: relative;

    &__input-display {
      width: 100%;
      cursor: pointer;
      ::v-deep .el-input__inner {
        cursor: pointer;
      }
    }
    &__input-filter {
      width: 100%;
      font-size: 13px;
      border-radius: 4px;
      border: 1px solid $COLOR_GRAY_CCC;
      padding: 8px 8px 8px 30px;
      margin-bottom: 5px;
    }

    &__icon-search {
      color: $COLOR_GRAY_CCC;
      position: absolute;
      left: 9px;
    }

    &__dropdown {
      position: absolute;
      z-index: $ZINDEX_DROPDOWN;
      top: 100%;
      //width: fit-content;
      width: 100%;
      max-width: #{$BREAK_PC}px;
      padding: 10px 8px 6px;
      border-radius: 4px;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);
      background-color: #ffffff;
      &.align-right {
        right: 0;
      }

      &__items {
        overflow-x: auto;
        min-height: 10vh;
        max-height: 40vh;
        &__scroll {
          max-height: 40vh;
          @include scrollbar;
        }
      }

      &__item, &__group {
        padding: 7px 6px 8px;
        line-height: 1;
        font-size: 15px;
        white-space: nowrap;
        overflow-x: scroll;
        @include hideScrollbar;
      }
      &__group {
        font-weight: $FONT_WEIGHT_BOLD;
      }
      &__item {
        cursor: pointer;
        &.heading {
          cursor: default;
          font-size: 12px;
          color: #aaa;
          line-height: 18px;
        }
        &.disabled {
          cursor: not-allowed;
          color: $COLOR_DISABLED;
        }
        &.hover {
          background-color: var(--color-primary);
          color: white;
        }

        &.new-item {
          display: flex;
          align-items: center;

          .new-item-tag {
            border-radius: 3px;
            background-color: $COLOR_GRAY_QUATERNARY;
            font-size: 11px;
            padding: 5px;
            font-weight: $FONT_WEIGHT_BOLD;
            color: white;
            margin-left: 8px;
          }
          &.hover {
            .new-item-tag {
              background-color: white;
              color: var(--color-primary);
            }
          }
        }
      }
    }
  }
</style>
